import * as TextUtil from './TextUtil';

export function filterTodayOrder(orders) {
  const todayOrders = [];
  const today = new Date();
  for (const order of orders) {
    if (order.status === 'cancelled') {
      continue;
    }
    if (['refunded', 'failed'].includes(order.payment_status)) {
      continue;
    }

    if (order.config.time) {
      const orderDateStr = order.config.time.split(' ')[0];
      if (orderDateStr === TextUtil.getDateStrFromTime(today.getTime())) {
        todayOrders.push(order);
      }
    } else {
      const orderDt = new Date(order.created);
      if (
        today.getMonth() === orderDt.getMonth() &&
        today.getDate() === orderDt.getDate()
      ) {
        todayOrders.push(order);
      }
    }
  }
  return todayOrders;
}
